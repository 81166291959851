<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img class="fp_logo" :src="logo" alt="" />

        <h2 class="brand-text text-primary ml-1"></h2>
      </b-link>

      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <b-col lg="5" class="d-flex align-items-center auth-bg px-0 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 pt-5 pt-xl-0 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Lets get Acquainted!
          </b-card-title>
          <b-card-text class="mb-2">
            Once you get to know us, you cant stay away from us!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="magicLinkLogin"
              >
                Get Magic Link
              </b-button>
            </b-form>
          </validation-observer>
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import AliffLogo from "@/assets/images/logo/AliffLogo.png";
import { supabase } from "@/supabase";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthServices from "@/apiServices/AuthServices";
import { TokenService } from "@/apiServices/storageService";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        status: "",
        password: "",
        email: "",
      },
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
      logo: AliffLogo,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    magicLinkLogin: async function () {
      try {
        // loading.value = true
        const { error } = await supabase.auth.signIn({
          email: this.form.email,
        });
        if (error) throw error;
        alert("Check your email for the login link!");
      } catch (error) {
        console.log("Error Login ", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.error_description || error.message,
            icon: "EditIcon",
            variant: "failure",
          },
        });
        // alert(error.error_description || error.message)
      } finally {
        // loading.value = false
      }
    },
  },
};
</script>

<style lang="scss">
// .fp_logo {
//   height: 50px;
//   width: 50px;
//   position: absolute !important;
//   top: 8px !important;
//   left: 941px !important;
// }

@import "@core/scss/vue/pages/page-auth.scss";
</style>
